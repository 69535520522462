<template>
  <div class="row justify-content-center px-8 px-lg-10">
    <div class="col-xl-12 col-xxl-7 justify-content-center">
      <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <!--begin::Form Wizard-->
        <form class="form" @submit.prevent="handleSubmit(createTransaction)">
          <!--begin::Step 1-->
          <div data-wizard-type="step-content" data-wizard-state="current">
            <div class="row">
              <div class="col-xl-12">
                <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label">{{
                    $t("branch_dash.customer_transaction")
                  }}</label>
                  <div class="col-lg-9 col-xl-9">
                    <multiselect
                      v-model="selectedCustomerTransaction"
                      :options="cryptoCurrencies"
                      :searchable="false"
                      :allow-empty="false"
                      open-direction="bottom"
                      @input="onChangeCustomerTransaction"
                      deselect-label=""
                      select-label=""
                      selected-label=""
                      track-by="message"
                      label="message"
                      :placeholder="$t('commons.choose')"
                      :loading="isLoading"
                    >
                      <template slot="singleLabel" slot-scope="{ option }">{{
                        $t(`branch_dash.wants_to_${option.type}`, {
                          code: option.code,
                        })
                      }}</template>
                      <template slot="option" slot-scope="{ option }">{{
                        $t(`branch_dash.wants_to_${option.type}`, {
                          code: option.code,
                        })
                      }}</template>
                      <template slot="noOptions">{{
                        "List is empty"
                      }}</template>
                    </multiselect>
                  </div>
                </div>

                <div v-show="selectedCustomerTransaction">
                  <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label">{{
                      $t("commons.commission")
                    }}</label>
                    <div class="col-lg-9 col-xl-9">
                      <validation-provider
                        rules="required"
                        :name="$t('commons.commission')"
                        v-slot="{ classes, errors }"
                      >
                        <div class="input-group" :class="classes">
                          <div class="input-group-prepend">
                            <span class="input-group-text">%</span>
                          </div>
                          <input
                            :class="classes"
                            type="number"
                            id="form-fee"
                            name="fee"
                            placeholder="0.00"
                            step="0.01"
                            min="2.00"
                            max="20.00"
                            autocomplete="off"
                            class="form-control"
                            v-model="calculate.fee"
                            @input="onChangeCommission"
                          />
                        </div>
                        <span class="error__message">{{ errors[0] }}</span>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label">{{
                      $t("branch_dash.quantity")
                    }}</label>
                    <div class="col-lg-9 col-xl-9">
                      <validation-provider
                        :rules="{required:!calculate.totalPrice}"
                        :name="$t('branch_dash.quantity')"
                        v-slot="{ classes, errors }"
                      >
                        <currency-input
                          class="form-control"
                          id="form-amount"
                          name="amount"
                          autocomplete="off"
                          :precision="amountPrecision"
                          :distractionFree="false"
                          v-model="calculate.amount"
                          placeholder="0.00000000"
                          @keyup="amountChanges"
                          @focus="amountIsFocused = true"
                          @blur="amountIsFocused = false"
                          :class="classes"
                        />
                        <span class="error__message">{{ errors[0] }}</span>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label">{{
                      $t("branch_dash.total_amount")
                    }}</label>
                    <div class="col-lg-9 col-xl-9">
                      <div class="input-group">
                        <div class="col-12 col-lg-7 px-0">
                          <validation-provider
                            :rules="{isAmountValid:messageKey}"
                            :name="$t('branch_dash.total_amount')"
                            v-slot="{ classes, errors }"
                          >
                            <currency-input
                              class="form-control"
                              :class="classes"
                              id="form-totalPrice"
                              name="totalPrice"
                              autocomplete="off"
                              :precision="totalPricePrecision"
                              :distractionFree="false"
                              v-model="calculate.totalPrice"
                              @keyup="totalPriceChanges"
                            />
                            <span class="error__message">{{ errors[0] }}</span>
                          </validation-provider>
                        </div>
                        <div class="col-12 col-lg-5 pr-0">
                          <validation-provider
                            rules="required"
                            :name="$t('commons.choose')"
                            v-slot="{ classes, errors }"
                          >
                            <multiselect
                              v-model="selectedCalculatableCurrency"
                              :options="calculatableCurrencies"
                              :internal-search="true"
                              :allow-empty="false"
                              open-direction="bottom"
                              @input="onChangeCalculatableCurrency"
                              deselect-label=""
                              select-label=""
                              selected-label=""
                              track-by="name"
                              label="name"
                              :placeholder="$t('commons.choose')"
                              :class="classes"
                            >
                              <template
                                slot="singleLabel"
                                slot-scope="{ option }"
                                >{{ option.name }}</template
                              >
                              <template slot="option" slot-scope="{ option }">{{
                                option.name
                              }}</template>
                              <template slot="noOptions">{{
                                "List is empty"
                              }}</template>
                            </multiselect>
                            <span class="error__message">{{ errors[0] }}</span>
                          </validation-provider>
                        </div>
                      </div>
                      <div class="mt-3">
                        <label class="checkbox" ref="includeFeeLabel">
                          <input
                            type="checkbox"
                            name="includeFee"
                            :checked="true"
                            @change="totalPriceChanges"
                            ref="includeFee"
                            v-model="calculate.includeFee"
                          />
                          <span></span>
                          <div class="d-inline-block ml-2">
                            {{ $t("branch_dash.inc_commission") }}
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="isCalculating">
            <div class="separator separator-dashed my-10"></div>
            <div data-wizard-type="step-content">
              <div class="row">
                <div class="col-xl-12">
                  <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label">
                      <Skeleton height="38px" />
                    </label>
                    <div class="col-lg-9 col-xl-9 col-form-label">
                      <Skeleton height="38px" />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label">
                      <Skeleton height="38px" />
                    </label>
                    <div class="col-lg-9 col-xl-9 col-form-label">
                      <Skeleton height="38px" />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label">
                      <Skeleton height="38px" />
                    </label>
                    <div class="col-lg-9 col-xl-9 col-form-label">
                      <Skeleton height="38px" />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label">
                      <Skeleton height="38px" />
                    </label>
                    <div class="col-lg-9 col-xl-9 col-form-label">
                      <Skeleton height="38px" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="messageKey=='success'">
            <div class="separator separator-dashed my-10"></div>
            <div data-wizard-type="step-content">
              <div class="row">
                <div class="col-xl-12">
                  <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label">{{
                      $t("branch_dash.current_price")
                    }}</label>
                    <div class="col-lg-9 col-xl-9 col-form-label">
                      <span class="price-value">{{
                        calculated.price_formatted
                      }}</span>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label
                      class="col-xl-3 col-lg-3 col-form-label"
                      :class="{
                        'text-success': selectedTransactionType === 'buy',
                      }"
                      >{{ $t("branch_dash.quantity") }}</label
                    >
                    <div class="col-lg-9 col-xl-9 col-form-label">
                      <span
                        class="price-value"
                        :class="{
                          'text-success': selectedTransactionType === 'buy',
                        }"
                      >
                        {{ calculated.amount_str }}
                      </span>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label">{{
                      $t("branch_dash.sub_total")
                    }}</label>
                    <div class="col-lg-9 col-xl-9 col-form-label">
                      <span class="price-value">{{
                        calculated.subTotal_formatted
                      }}</span>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label">{{
                      $t("branch_dash.commission_amount")
                    }}</label>
                    <div class="col-lg-9 col-xl-9 col-form-label">
                      <span class="price-value">{{
                        calculated.fee_formatted
                      }}</span>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label">{{
                      $t("branch_dash.total_volume")
                    }}</label>
                    <div class="col-lg-9 col-xl-9 col-form-label">
                      <span class="price-value">{{
                        calculated.grandTotal_formatted
                      }}</span>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label
                      class="
                        col-xl-3 col-lg-3 col-form-label
                        text-success
                        font-weight-bolder font-size-h6
                      "
                      v-if="selectedTransactionType === 'sell'"
                    >
                      {{ $t("branch_dash.payable_to_customer") }}
                    </label>
                    <label
                      class="
                        col-xl-3 col-lg-3 col-form-label
                        text-danger
                        font-weight-bolder font-size-h6
                      "
                      v-if="selectedTransactionType === 'buy'"
                    >
                      {{ $t("branch_dash.customer_payment") }}
                    </label>
                    <div class="col-lg-9 col-xl-9 col-form-label">
                      <span
                        class="price-value font-weight-bolder font-size-h6"
                        :class="[
                          selectedTransactionType === 'sell'
                            ? 'text-success'
                            : 'text-danger',
                        ]"
                      >
                        {{ calculated.grandTotal_formatted }}
                      </span>
                    </div>
                  </div>
                  <div class="form-group row" v-if="selectedTransactionType === 'sell'">
                    <label class="col-xl-3 col-lg-3 col-form-label">{{
                      $t("branch_dash.merchant_wallet_address")
                    }}</label>
                    <div class="col-lg-9 col-xl-9 col-form-label">
                      <input type="text" class="form-control" disabled :value="calculated.wallet" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-show="messageKey=='success'">
            <div class="separator separator-dashed my-10"></div>

            <div class="pb-5" data-wizard-type="step-content">
              <div class="row">
                <div class="col-xl-12">
                  <div class="form-group row">
                    <div class="col-lg-9 col-xl-6">
                      <h3 class="mb-10 font-weight-bold text-dark">
                        {{ $t("branch_dash.customer_information_title") }}
                      </h3>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label">{{
                      $t("branch_dash.customer")
                    }}</label>
                    <div class="col-lg-9 col-xl-9">
                      <validation-provider rules="required" :name="$t('branch_dash.customer')" v-slot="{ classes, errors }">
                        <multiselect
                          v-model="selectedCustomer"
                          id="ajax"
                          :options="customers"
                          :placeholder="$t('branch_dash.search_or_select_customer')"
                          :loading="customersLoading"
                          :internal-search="false"
                          open-direction="bottom"
                          @search-change="onSearchCustomers"
                          @input="onChangeCustomer"
                          deselect-label=""
                          select-label=""
                          selected-label=""
                          :class="classes"
                        >
                            <template slot='singleLabel' slot-scope='{ option }'>{{ option.name }} {{ option.surname }}</template>
                            <template slot='option' slot-scope='{ option }'>{{ option.name }} {{ option.surname }}</template>
                            <template slot='noOptions'>&nbsp;</template>
                            <template slot='noResult'>{{ $t('branch_dash.no_customer_found') }}</template>
                        </multiselect>
                        <span class="error__message">{{ errors[0] }}</span>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="form-group row" v-if="selectedCustomer && selectedTransactionType === 'buy'">
                    <label class="col-xl-3 col-lg-3 col-form-label">{{
                      $t("branch_dash.wallet_address")
                    }}</label>
                    <div class="col-lg-9 col-xl-9">
                      <validation-provider rules="required" :name="$t('branch_dash.wallet_address')" v-slot="{ classes, errors }">
                        <multiselect
                          v-model="selectedCustomerWallet"
                          :options="customerWallets"
                          :allow-empty="false"
                          open-direction="bottom"
                          @input="onChangeSelectedCustomerWallet"
                          deselect-label=""
                          select-label=""
                          selected-label=""
                          track-by="id"
                          label="address"
                          :placeholder="$t('commons.choose')"
                          :class="classes"
                        >
                          <template slot="singleLabel" slot-scope="{ option }">{{ option.address }}</template>
                          <template slot="option" slot-scope="{ option }">{{ option.address }}</template>
                        </multiselect>
                        <div class="d-flex align-items-center" v-if="customerWallets.length === 0">
                          <i class="la la-info-circle text-dark mr-1"></i>
                          <span class="form-text">{{ $t('branch_dash.no_wallets_found_in_trade') }}</span>
                        </div>
                        <span class="error__message">{{ errors[0] }}</span>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label">{{
                      $t("branch_dash.sales")
                    }}</label>
                    <div class="col-lg-9 col-xl-9">
                      <input
                        type="text"
                        id="form-order_id"
                        name="order_id"
                        class="form-control"
                        :value="customer.order_id"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label">{{
                      $t("commons.name_surname")
                    }}</label>
                    <div class="col-lg-9 col-xl-9">
                      <input
                        type="text"
                        name="fullname"
                        class="form-control"
                        :value="customer.fullname"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label">{{
                      $t("commons.mobile_phone")
                    }}</label>
                    <div class="col-lg-9 col-xl-9">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text"
                            ><i class="la la-phone"></i
                          ></span>
                        </div>
                        <input
                          type="text"
                          name="phone"
                          class="form-control"
                          :value="customer.phone"
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label">{{
                      $t("commons.e_mail")
                    }}</label>
                    <div class="col-lg-9 col-xl-9">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text"
                            ><i class="la la-at"></i
                          ></span>
                        </div>
                        <input
                          type="email"
                          name="email"
                          class="form-control"
                          :value="customer.email"
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="d-flex justify-content-end border-top mt-5 pt-10">
              <button
                type="submit"
                class="
                  btn btn-success
                  font-weight-bolder
                  text-uppercase
                  px-9
                  py-4
                "
              >
                {{ $t("commons.continue") }}
              </button>
            </div>
          </div>

          <!--end::Actions-->
        </form>
        <!--end::Form Wizard-->
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import debounce from 'lodash/debounce';
import { Skeleton } from 'vue-loading-skeleton';

export default {
  name: "NewTransaction",
  components: {
    Multiselect,
    Skeleton
  },
  data() {
    return {
      selectedCustomerTransaction: null,
      selectedCalculatableCurrency: null,
      amountIsFocused: false,
      calculate: {
        currency_id: "",
        fee: 10,
        amount: 0,
        totalPrice: 0,
        total_currency_id: "eur",
        includeFee: true,
      },
      customer: {
        who_will_send: 1,
        order_id: "",
        fullname: "",
        phone: "",
        email: "",
      },
      calculateType: 'amount',
      selectedCustomer: null,
      selectedCustomerWallet: null,
      isCalculating: false
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.transactions.isLoading,
      calculated: (state) => state.transactions.calculated,
      calcAction: (state) => state.transactions.calculated.action,
      cryptoCurrencies: (state) =>
        state.transactions.createTransactionData.cryptoCurrencies,
      calculatableCurrencies: (state) =>
        state.transactions.createTransactionData.calculatableCurrencies,
      customersLoading: (state) => state.transactions.customersLoading,
      customers: (state) => state.transactions.customers,
    }),
    messageKey() {
      return this.calculated?.message_key;
    },
    amountPrecision() {
      return this.selectedCustomerTransaction?.decimal || 8;
    },
    totalPricePrecision() {
      return this.selectedCalculatableCurrency?.decimal || 2;
    },
    selectedTransactionType() {
      return this.selectedCustomerTransaction?.type;
    },
    customerWallets() {
      if(this.selectedCustomer?.wallets && this.selectedCustomer.wallets.length > 0) return this.selectedCustomer.wallets;
      return [];
    }
  },
  methods: {
    ...mapActions("transactions", ["POST_CALCULATE"]),
    createTransaction() {
      // const self = this;
      let params = {
        ...this.calculate,
        ...this.customer,
        wallet: this.calculated?.wallet || "",
      };

      const transaction = {
        selectedCustomerTransaction: this.selectedCustomerTransaction,
        includeFee: this.calculate.includeFee ? "commons.yes" : "commons.no",
        price_formatted: this.calculated.price_formatted,
        grandTotal_formatted: this.calculated.grandTotal_formatted,
        amount_str: this.calculated.amount_str,
        subTotal_formatted: this.calculated.subTotal_formatted,
        fee_formatted: this.calculated.fee_formatted,
        selectedCalculatableCurrency: this.selectedCalculatableCurrency,
        selectedTransactionType: this.selectedTransactionType,
        customer: this.customer,
        wallet: this.calculated?.wallet || "",
      };

      if(this.calculateType === 'amount') {
        params.totalPrice = null;
      } else {
        params.amount = this.calculated.amount;
      }

      this.$store.commit('transactions/SET_TRANSACTION_REQUEST_PARAMS', params);
      this.$store.dispatch("transactions/SET_TRANSACTION_SUMMARY", transaction);
      this.$emit('showSummary')

      /* this.$store
        .dispatch("transactions/CREATE_TRANSACTION", params)
        .then(function () {
          self.$store
            .dispatch("transactions/SET_TRANSACTION_SUMMARY", transaction)
            .then(() => {
              self.$router.push("/branch-dashboard/summary");
            });
        })
        .catch(() => {}); */
    },
    calculateTransaction() {
      let params = this.calculate;
      if(this.calculateType === 'amount') {
        // eslint-disable-next-line no-unused-vars
        const { totalPrice, ...rest } = this.calculate;
        params = rest;
      } else {
        // eslint-disable-next-line no-unused-vars
        const { amount, ...rest } = this.calculate;
        params = rest;
      }
      this.isCalculating = true;
      this.POST_CALCULATE(params).then(() => {
        this.isCalculating = false;
        this.$refs.form?.setErrors({[this.$t('branch_dash.total_amount')]: null});
        if(`${this.messageKey}` === 'error') {
          this.$refs.form?.setErrors({[this.$t('branch_dash.total_amount')]: this.$t('branch_dash.min_amount_error')});
        }
        if(this.calculateType === 'amount') {
          this.calculate.totalPrice = this.calculated.subTotal ?  parseFloat(`${this.calculated.subTotal}`.replace(/,/g, '')) : 0;
          // this.calculate.totalPrice = null;
          // this.amountIsFocused && (this.calculate.totalPrice = null);
        } else {
          this.calculate.amount = this.calculated.amount ? parseFloat(`${this.calculated.amount}`.replace(/,/g, '')) : 0;
          // this.calculate.amount = null;
        }
      }).catch(() => {
        this.isCalculating = false;
      })
    },
    onChangeCustomerTransaction() {
      this.calculate.currency_id = this.selectedCustomerTransaction.action;
      // disable selected currency in calculatableCurrencies
      this.$store.commit(
        "transactions/FILTER_CALCULATABLE_CURRENCIES",
        this.selectedCustomerTransaction.code
      );
      if (this.calculated) {
        this.calculate.amount = null;
        this.calculate.totalPrice = null;
        this.$store.commit("transactions/SET_CALCULATED", null);
      }
      this.selectedCustomer = null;
      this.customer = {
        who_will_send: 1,
        order_id: "",
        fullname: "",
        phone: "",
        email: "",
      };
      this.$store.commit('transactions/SET_CUSTOMERS', []);
      this.$refs.form.reset();
    },
    onChangeCalculatableCurrency() {
      this.calculate.total_currency_id = this.selectedCalculatableCurrency.code;
      if (this.calculate.amount) {
        this.calculate.totalPrice = null;
        this.calculateTransaction()
        return;
      }
      if (this.calculate.totalPrice) {
        this.calculateTransaction()
      }
    },
    amountChanges: debounce(function() {
      this.calculateType = "amount";
      this.$refs.includeFee.disabled = true;
      this.calculate.includeFee = false;
      this.$refs.includeFeeLabel.classList.value = "checkbox checkbox-disabled";
      this.calculate.totalPrice = null;
      if (
        this.calculate.amount &&
        this.calculate?.fee >= 2 &&
        this.calculate?.fee <= 20
      ) {
        this.calculateTransaction()
      }
    }, 600),
    totalPriceChanges: debounce(function() {
      this.calculateType = "totalPrice";
      this.$refs.includeFee.disabled = false;
      this.$refs.includeFeeLabel.classList.value = "checkbox";
      if (
        this.calculate.totalPrice &&
        this.calculate?.fee >= 2 &&
        this.calculate?.fee <= 20
      ) {
        this.calculateTransaction()
      }
    }, 600),
    onChangeCommission: debounce(function() {
      if (this.calculate?.fee >= 2 && this.calculate?.fee <= 20) {
          this.calculateType = "amount";
          this.calculate.totalPrice = null;
          if (
            this.calculate.amount &&
            this.calculate?.fee >= 2 &&
            this.calculate?.fee <= 20
          ) {
            this.calculateTransaction()
          }
      }
    }, 600),
    onSearchCustomers: debounce(function(query) {
      if(query) {
        this.$store.dispatch('transactions/searchCustomersAction', {
          query,
          currencyID: this.selectedCustomerTransaction.id
        })
      }
    }, 500),
    onChangeCustomer() {
      this.customer = {
        who_will_send: 1,
        order_id: "",
        fullname: "",
        phone: "",
        email: "",
      };
      this.selectedCustomerWallet = null;
      if(this.selectedTransactionType === 'buy') {
        this.$store.commit('transactions/UPDATE_CALCULATED_WALLET', null);
      }
      if(this.selectedCustomer) {
        this.customer = {
          ...this.customer,
          email: this.selectedCustomer.email,
          order_id: `invoice_miracle_${this.selectedCustomer.id}`,
          fullname: `${this.selectedCustomer.name} ${this.selectedCustomer.surname}`,
          phone: `${this.selectedCustomer.phone}`,
        }
      }
    },
    onChangeSelectedCustomerWallet() {
      this.$store.commit('transactions/UPDATE_CALCULATED_WALLET', this.selectedCustomerWallet?.address)
    },
    setEur(vals) {
      if (vals.length > 0) {
        this.selectedCalculatableCurrency = vals.find((val) => val.code.toUpperCase() === "EUR");
      }
    }
  },
  created() {
    this.$store.commit("transactions/SET_CALCULATED", null);
    if (this.calculatableCurrencies.length > 0) {
      this.setEur(this.calculatableCurrencies)
    }
  },
  watch: {
    calculatableCurrencies: function(val) {
      if(val?.length > 0) {
        this.setEur(val)
      }
    }
  }
};
</script>
<style>
.price-value {
  font-weight: bold;
}
</style>
